import * as React from "react";

function SvgShocked(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <path d="M256 0C114.615 0 0 114.615 0 256s114.615 256 256 256 256-114.615 256-256S397.385 0 256 0zm0 480C132.288 480 32 379.712 32 256S132.288 32 256 32s224 100.288 224 224-100.288 224-224 224z" />
      <circle cx={176} cy={176} r={32} />
      <circle cx={336} cy={176} r={32} />
      <path d="M256 240c-53.019 0-96 42.981-96 96s42.981 96 96 96 96-42.981 96-96-42.981-96-96-96zm0 160a64.002 64.002 0 01-42.08-16c24.083-20.946 59.917-20.946 84 0A64.004 64.004 0 01256 400zm60.16-42.88c-35.102-28.166-85.058-28.166-120.16 0a64.01 64.01 0 01-4-21.12c0-35.346 28.654-64 64-64 35.346 0 64 28.654 64 64a64.005 64.005 0 01-3.84 21.12z" />
    </svg>
  );
}

export default SvgShocked;
