import * as React from "react";

function SvgDevil(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512.328 512.328"
      {...props}
    >
      <path d="M481.348 135.976c30.816-69.088 2.688-124.736 1.408-127.168C479.3 2.344 472.036-1.24 464.74.392c-7.136 1.792-12.096 7.776-12.096 15.136 0 .16-2.464 17.216-33.28 44.416-45.728-38.4-103.04-59.616-163.2-59.616S138.692 21.544 92.932 59.944C62.628 33.192 59.748 16.328 59.684 16.328c0-7.328-4.992-13.76-12.128-15.52-7.072-1.792-14.528 1.536-17.984 8-1.28 2.432-29.408 58.08 1.408 127.168C11.044 173.032.164 214.344.164 256.328c0 141.152 114.848 256 256 256s256-114.848 256-256c0-42.016-10.88-83.296-30.816-120.352zM256.164 480.328c-123.488 0-224-100.512-224-224 0-39.104 10.432-77.728 30.144-111.648.288-.48.288-.992.512-1.504.48-1.024.832-2.048 1.056-3.136.224-.992.352-1.92.352-2.912.064-1.056 0-2.016-.16-3.04a17.158 17.158 0 00-.864-3.104c-.192-.544-.192-1.088-.448-1.6-13.472-26.496-15.744-50.624-14.208-69.088C56.58 70.152 67.652 81.32 83.044 93.48c.544.416 1.184.544 1.728.896.96.576 1.92 1.152 2.944 1.504.928.32 1.856.48 2.784.64 1.056.16 2.08.288 3.168.256.96-.032 1.888-.256 2.816-.48 1.056-.256 2.016-.512 3.008-.96.928-.416 1.76-.992 2.592-1.6.64-.416 1.312-.608 1.856-1.12 41.344-38.88 95.424-60.288 152.224-60.288s110.88 21.408 152.224 60.288c.544.512 1.248.736 1.856 1.184.8.576 1.536 1.12 2.4 1.504.992.448 1.984.704 3.04.96.928.224 1.792.448 2.72.512.352.032.64.192.96.192.672 0 1.312-.32 1.952-.384 1.12-.128 2.176-.288 3.264-.672.96-.32 1.824-.864 2.72-1.408.64-.384 1.376-.544 1.984-1.024 15.36-12.16 26.464-23.328 34.496-33.184 1.536 18.496-.736 42.592-14.208 69.088-.256.512-.256 1.088-.448 1.6a17.58 17.58 0 00-.864 3.072 16.176 16.176 0 00-.192 3.04c.032.992.16 1.952.352 2.944a18.072 18.072 0 001.056 3.136c.224.48.224 1.024.512 1.504 19.744 33.92 30.176 72.544 30.176 111.648 0 123.488-100.512 224-224 224z" />
      <path d="M206.34 203.016l-68.128-55.136c-6.848-5.568-16.928-4.48-22.496 2.4-5.568 6.88-4.48 16.928 2.4 22.496l33.088 26.784c-13.76 8.416-23.04 23.488-23.04 40.768 0 26.464 21.536 48 48 48s48-21.536 48-48c0-14.592-6.496-28.192-17.824-37.312zm-30.176 53.312c-8.8 0-16-7.2-16-16s7.2-16 16-16c3.712 0 7.168 1.216 10.016 3.52l.032.032c3.808 3.072 5.952 7.584 5.952 12.448 0 8.8-7.2 16-16 16zM396.612 150.248c-5.536-6.848-15.648-7.936-22.496-2.368l-68.096 55.104h-.032l-.032.032c-11.296 9.152-17.792 22.72-17.792 37.312 0 26.464 21.536 48 48 48s48-21.536 48-48c0-17.28-9.28-32.352-23.008-40.8l33.088-26.784c6.88-5.568 7.936-15.616 2.368-22.496zm-60.448 106.08c-8.8 0-16-7.2-16-16 0-4.864 2.144-9.344 5.952-12.448l.032-.032c2.848-2.304 6.304-3.52 10.016-3.52 8.8 0 16 7.2 16 16s-7.2 16-16 16zM369.284 346.824c-6.24-6.24-16.384-6.24-22.624 0-48.384 48.384-132.64 48.384-180.992 0-6.24-6.24-16.384-6.24-22.624 0s-6.24 16.384 0 22.624c30.208 30.208 70.368 46.88 113.12 46.88s82.912-16.672 113.12-46.88c6.24-6.24 6.24-16.384 0-22.624z" />
    </svg>
  );
}

export default SvgDevil;
