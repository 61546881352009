import * as React from "react";

function SvgRooster(props) {
  return (
    <svg
      height="512pt"
      viewBox="-31 0 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M321.836 338.844c10.523 10.586 24.184 18.043 39.445 20.875C367.746 394.543 398.332 421 435 421h15V261.094c0-56.992-42.902-106.989-99.375-109.946-56.555-2.96-102.297 38.364-109.566 89.852H225c-24.813 0-45-20.188-45-45v-30c0-5.137-.52-10.152-1.508-15H195c41.457 0 75-34.535 75-76 0-41.355-33.645-75-75-75h-6.215l-4.39 4.395C167.883 20.906 145.925 30 122.575 30H75c-24.813 0-45 20.188-45 45a46.09 46.09 0 007.906 25.832C15.281 113.758 0 138.129 0 166v14.988h39.441C13.921 210.621 0 247.996 0 287.508c0 73.539 48.21 135.953 114.504 156.945L80.73 512h168.54l-33.774-67.547c50.137-15.875 89.922-55.45 106.34-105.61zM330 286v-30c0-8.27 6.73-15 15-15s15 6.73 15 15v72.422c-17.46-6.195-30-22.867-30-42.422zm19.055-104.895c39.12 2.051 70.945 37.93 70.945 79.989V388.43c-17.46-6.192-30-22.871-30-42.43v-90c0-24.813-20.188-45-45-45-19.555 0-36.227 12.54-42.422 30h-31.09c6.922-34.441 38.45-61.938 77.567-59.895zM75 60h47.574c29.055 0 56.5-10.48 78.016-29.656C222.773 33.105 240 52.078 240 75c0 24.934-20.605 46-45 46h-30.04c-13.698-18.203-35.476-30-59.96-30H75c-7.992 0-15-7.477-15-16 0-8.27 6.73-15 15-15zm-15 63.578V151H32.57c4.528-12.766 14.664-22.895 27.43-27.422zM200.73 482h-71.46l15.539-31.078A163.975 163.975 0 00163.492 452h3.016c6.316 0 12.547-.375 18.68-1.078zm-34.222-60h-3.016C89.882 422 30 361.668 30 287.508c0-35.656 13.887-69.18 39.098-94.395L90 172.215V121h15c24.813 0 45 20.188 45 45v30c0 41.355 33.645 75 75 75h75v16.508C300 361.668 240.117 422 166.508 422zm0 0" />
      <path d="M192.742 280.89c-10.586 0-20.11 3.883-27.742 11.051-7.633-7.168-17.156-11.05-27.742-11.05-23.969 0-42.742 19.816-42.742 45.117 0 30.449 26.613 47.887 60.66 77.394l9.824 8.512 9.824-8.512c30.38-26.332 60.66-46.101 60.66-77.394 0-25.301-18.773-45.117-42.742-45.117zM165 372.329c-24.96-21.137-40.484-33.512-40.484-46.32 0-7.512 4.375-15.117 12.742-15.117 6.441 0 10.355 5.992 12.508 11.015L153.66 331h22.68l3.894-9.098c2.153-5.023 6.07-11.011 12.508-11.011 8.367 0 12.746 7.605 12.746 15.117-.004 14.375-21.543 30.273-40.488 46.32zm0 0" />
    </svg>
  );
}

export default SvgRooster;
