import * as React from "react";

function SvgHorn(props) {
  return (
    <svg
      height={512}
      viewBox="0 0 511.218 511.218"
      width={512}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M482.252 286.5l6.674.354 22.292-22.293L246.977.319l-22.293 22.292c10.374 110.195-99.342 273.064-118.139 299.579-88.557-11.094-142.353 96.628-78.796 160.89 64.217 63.185 170.652 10.815 161.021-77.682 24.255-17.047 182.308-124.769 293.482-118.898zm-21.395-29.875c-14.911.841-30.747 3.175-47.464 6.972L247.942 98.145c3.796-16.717 6.13-32.553 6.971-47.465zM140.486 461.866c-24.09 25.104-67.436 25.108-91.525-.001-40.737-40.557-11.083-110.834 45.763-110.449 56.846-.379 86.501 69.894 45.762 110.45zm-3.712-130.611c28.367-38.675 87.015-144.91 102.034-199.818L380.1 272.73c-54.776 14.922-161.741 74.006-200.088 102.217-8.585-18.353-24.955-34.903-43.238-43.692zM392.384 411.177l21.21-21.21 53.025 53.025-21.21 21.21zM337.516 436.432l27.39-12.21 30.525 68.475-27.39 12.21zM426.483 362.99l12.21-27.39 68.474 30.525-12.21 27.39zM44.945 63.663l21.21-21.21 53.025 53.025-21.21 21.21zM115.718 13.984l27.39-12.21 30.525 68.475-27.39 12.21zM4.194 140.496l12.21-27.39 68.476 30.525-12.21 27.39z" />
    </svg>
  );
}

export default SvgHorn;
