import * as React from "react";

function SvgCuckoo(props) {
  return (
    <svg
      height={512}
      viewBox="0 0 508 508"
      width={512}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={373.063} cy={120.055} r={14.883} />
      <path d="M7.841 77.177A14.883 14.883 0 000 90.289c0 32.666 14.099 63.738 38.731 85.291l81.492 70.691c1.912 43.419 21.142 84.639 53.444 114.006 26.843 24.404 59.869 38.799 95.214 41.915v30.401h-44.648c-8.219 0-14.883 6.664-14.883 14.883s6.664 14.883 14.883 14.883h59.531c3.948 0 7.733-1.569 10.523-4.36 10.609-10.609 27.875-10.609 38.484 0 5.811 5.811 15.235 5.812 21.047 0s5.812-15.235 0-21.047c-14.919-14.921-36.125-19.814-55.172-14.692v-20.104c.384-.034.766-.06 1.15-.096 84.365-8.045 147.679-83.087 147.679-169.225v-78.446l57.961-85.516a14.886 14.886 0 00-.108-16.858 14.885 14.885 0 00-15.777-5.941l-69.668 17.192c-9.603-8.804-21.271-14.475-34.434-16.616-46.657-7.593-86.802 29.09-86.802 71.311 0 42.263-34.405 76.509-76.508 76.509h-.001c-15.155 0-29.829-4.444-42.476-12.875L23.102 77.882a14.888 14.888 0 00-15.261-.705zm451.21 7.083l-15.094 22.27c-1.462-6.069-3.362-11.807-5.708-17.137zm-130.637 35.795c0-26.671 23.668-48.67 52.258-44.027 23.192 3.774 37.039 26.843 37.039 61.709v95.099c0 71.82-53.034 133.138-120.737 139.595-38.341 3.655-75.019-8.481-103.282-34.176-18.412-16.739-31.634-38.183-38.494-61.661a153.998 153.998 0 00100.066 36.938c39.651 0 69.9-35.616 63.369-74.804-2.731-16.384-11.885-30.336-25.777-39.288-.491-.317-.998-.606-1.497-.908 24.072-20.711 37.055-50.907 37.055-78.477zm-165.225 86.33c17.515 11.678 37.9 17.849 58.951 17.849 9.333 0 18.795-1.273 27.468-3.588 17.809-4.757 36.573 4.429 39.665 22.974 3.498 20.99-12.686 40.145-34.009 40.145-30.114 0-59.18-10.921-81.891-30.794-1.281-1.123-21.656-18.787-115.089-99.835a83.484 83.484 0 01-22.182-30.94z" />
    </svg>
  );
}

export default SvgCuckoo;
