import * as React from "react";

function SvgYodle(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <path d="M461.308 346.413l-10.541-56.943h-.001l-17.973-97.09a57.44 57.44 0 00-19.22-33.298l56.828-56.829-10.606-10.609c-33.298-33.299-87.482-33.297-120.779 0l-53.68 53.68H135.77c-27.729 0-51.517 19.79-56.565 47.054l-17.973 97.09h-.001l-15.33 82.822L0 375.149v70.18h512V343.255l-50.692 3.158zm-41.052-56.944H233.402l20.491-20.491a49.422 49.422 0 005.563 2.49c.259.097.514.205.774.298l.104.034a50.257 50.257 0 0016.841 2.884c13.41 0 26.016-5.222 35.498-14.705l79.546-79.545a27.49 27.49 0 0111.076 17.405l16.961 91.63zm-157.365-79.271l64.873-64.873 32.466-32.466c10.802-10.801 24.988-16.201 39.177-16.201 9.36 0 18.722 2.351 27.109 7.052l-41.549 41.549h-.001l-93.507 93.508a20.293 20.293 0 01-4.773 3.549 20.1 20.1 0 01-9.51 2.368 20.077 20.077 0 01-14.285-5.917c-3.815-3.815-5.917-8.888-5.917-14.285s2.102-10.468 5.917-14.284zM108.705 197.84c2.415-13.046 13.798-22.514 27.064-22.514h119.564l-13.658 13.658c-9.481 9.482-14.705 22.089-14.705 35.499 0 2.514.184 5 .544 7.443a49.654 49.654 0 002.373 9.501 49.53 49.53 0 002.789 6.337l-41.705 41.705H91.743l16.962-91.629zM86.191 319.471h339.618l5.335 28.82-354.376 22.076 9.423-50.896zM482 415.328H30.001V403.34l10.271-.64 426.536-26.57v-.001l15.192-.947v40.146z" />
    </svg>
  );
}

export default SvgYodle;
