import * as React from "react";

function SvgSanta(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <path d="M466.027 377.023V212.028C466.027 90.366 363.944 0 256 0 148.081 0 45.973 90.331 45.973 212.028v61.522c-17.461 6.193-30.004 22.868-30.004 42.426v31.004c0 24.815 20.189 44.905 45.006 44.905h300.08a74.917 74.917 0 00-15.043 45.106c0 41.36 33.649 75.01 75.01 75.01s75.01-33.649 75.01-75.01c-.001-24.488-11.798-46.269-30.005-59.968zM75.976 212.028c0-104.709 88.262-182.024 180.024-182.024 91.681 0 180.024 77.228 180.024 182.024v151.463c-4.849-.988-9.865-1.509-15.002-1.509s-10.153.521-15.002 1.509V195.96c0-8.285-6.717-15.002-15.002-15.002s-15.002 6.717-15.002 15.002v75.01H75.976v-58.942zM60.974 361.98c-8.272 0-15.002-6.73-15.002-15.002v-31.004c0-8.272 6.73-15.002 15.002-15.002h315.041v61.008H60.974zm360.048 120.016c-24.816 0-45.006-20.19-45.006-45.006 0-25.083 20.19-45.006 45.006-45.006s45.006 20.19 45.006 45.006-20.19 45.006-45.006 45.006z" />
    </svg>
  );
}

export default SvgSanta;
