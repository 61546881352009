import * as React from "react";

function SvgWitch(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <path d="M409.192 321.05L364.39 118.673c35.394-5.145 67.188-24.999 87.373-55.275L478.48 23.32A14.999 14.999 0 00466 0H216.075c-36.269 0-67.303 25.9-73.791 61.584-.016.086-.03.173-.044.26L99.645 322.005C35.353 341.708 0 371.447 0 406c0 30.759 28.583 58.52 80.483 78.168C127.892 502.116 190.225 512 256 512c133.224 0 256-41.397 256-106 0-35.14-36.501-65.268-102.808-84.95zm-21.123 43.381c-7.471 6.613-27.219 14.892-57.952 20.44a45.03 45.03 0 00.882-8.871v-20.81c20.277-3.374 37.945-8.099 51.957-13.854l5.113 23.095zm-216.246-297.6C175.763 45.483 194.354 30 216.075 30h221.898l-11.171 16.756C408.749 73.834 378.543 90 346 90a15 15 0 00-14.645 18.242l45.003 203.283c-12.074 5.735-29.542 10.633-50.268 14.073C318.637 311.014 303.471 301 286 301h-60c-17.47 0-32.635 10.013-40.088 24.595-22.867-3.805-41.894-9.425-53.856-15.875l39.767-242.889zM301 346v30c0 6.047-3.604 11.257-8.772 13.629-7.897.608-53.816 2.424-72.449.003C214.608 387.263 211 382.05 211 376v-30c0-8.271 6.729-15 15-15h60c8.271 0 15 6.729 15 15zm-173.981-5.515c14.308 6.149 32.64 11.161 53.981 14.705V376c0 3.046.308 6.021.888 8.899-32.62-5.886-52.094-14.795-58.679-21.145l3.81-23.269zM256 482c-134.327 0-226-42.181-226-76 0-17.128 24.638-36.209 64.17-50.555l-1.537 9.385a15 15 0 001.193 8.73c6.395 13.8 23.544 25.029 50.971 33.377 19.411 5.908 42.901 10.093 68.735 12.292a44.86 44.86 0 0012.466 1.77h60c4.37 0 8.591-.638 12.589-1.805 33.953-3 102.669-12.907 118.945-44.332a15 15 0 001.326-10.141l-2.142-9.678C456.918 369.436 482 388.687 482 406c0 33.822-91.679 76-226 76z" />
    </svg>
  );
}

export default SvgWitch;
