import React from "react";

export const Footer: React.FC = () => {

    return (
        <footer className="footer">
            <p>© 2020 - Berit Haak</p>
            <p>
                Icons made by <a
                    className="link"
                    href="https://www.flaticon.com/authors/freepik"
                    title="Freepik"
                >
                    Freepik
                </a> from <a
                    className="link"
                    href="https://www.flaticon.com/"
                    title="Flaticon"
                >
                    www.flaticon.com
                </a>
            </p>
        </footer>
    )
};


