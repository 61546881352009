import * as React from "react";

function SvgBroken(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <path d="M421 195c0-25.165-4.79-41.466-30.238-182.661A15 15 0 00376 0H136a15 15 0 00-14.764 12.349C98.364 139.73 91 167.481 91 195c0 73.936 49.775 138.823 120 158.744v69.763c-34.191 6.969-60 37.275-60 73.493 0 8.284 6.716 15 15 15h180c8.284 0 15-6.716 15-15 0-36.219-25.809-66.524-60-73.493v-69.763C371.225 333.823 421 268.936 421 195zM286 452c19.556 0 36.239 12.539 42.43 30H183.57c6.191-17.461 22.874-30 42.43-30 8.284 0 15-6.716 15-15v-95.032a15 15 0 00-12.004-14.698C166.419 314.513 121 258.885 121 195c0-23.198 6.956-51.115 27.54-165h86.649l-23.419 70.257C208.538 109.954 215.765 120 226 120h69.188l-23.419 70.257c-2.62 7.859 1.628 16.354 9.487 18.974 7.861 2.62 16.355-1.629 18.974-9.487l30-90C333.462 100.046 326.235 90 316 90h-69.188l20-60h96.655C386.43 156.87 391 173.929 391 195c0 63.885-45.419 119.513-107.996 132.27A15 15 0 00271 341.968V437c0 8.284 6.716 15 15 15z" />
    </svg>
  );
}

export default SvgBroken;
