import './App.css';
import React from "react";
import {SoundButton} from "./components/sound-button";
import {sounds} from "./_utils/sound";
import {Footer} from "./components/footer";

const themes = [
    'theme--red',
    'theme--green',
    'theme--yellow',
    'theme--orange',
    'theme--blue',
    'theme--turquoise',
    'theme--softPink',
    'theme--pink',
    'theme--violet'];

const theme = themes[ Math.floor(Math.random() * themes.length) ];

const App = () => {
  return (
    <div className={`app ${theme}`}>
        <header className="header">
            <h1 className="header__headline">digital sound nibbles</h1>
        </header>
        <div className="sounds">
            {sounds.map((sound) => {
                return (
                    <SoundButton
                        className="sounds__item"
                        key={sound.title}
                        soundSrc={sound.src}
                        icon={sound.icon}
                        title={sound.title}
                    />
                );
            })}
        </div>
        <Footer />
    </div>
  );
};

export default App;
