import {RefObject, useEffect} from "react";

export const useOnClickOutside = (ref: RefObject<any>, handler: (event: MouseEvent | TouchEvent) => void) => {
    useEffect(
        () => {
            let dragging = false;
            const listener = (event: MouseEvent | TouchEvent) => {
                // Do nothing if clicking ref's element or descendent elements
                // or if the user is dragging on mobile devices
                if (!ref.current || dragging) {
                    return;
                }

                if ('target' in event) {
                    if (ref.current.contains(event.target)) {
                        return;
                    }
                }

                if ('changedTouches' in event) {
                    if (ref.current.contains(event.changedTouches[0].target)) {
                        return;
                    }
                }

                handler(event);
            };

            document.addEventListener('mousedown', listener);
            document.addEventListener('touchstart', () => dragging = false);
            document.addEventListener('touchmove', () => dragging = true);
            document.addEventListener('touchend', listener);

            return () => {
                document.removeEventListener('mousedown', listener);
                document.removeEventListener('touchstart', () => dragging = false);
                document.removeEventListener('touchmove', () => dragging = true);
                document.removeEventListener('touchend', listener);
            };
        },
        [ref, handler]
    );
}
