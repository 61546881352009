import * as React from "react";

function SvgPirate(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <path d="M326.36 390c-21.403 0-41.891-9.125-56.211-25.035L265.68 360h-19.36l-4.469 4.965A75.737 75.737 0 01185.639 390H181v30h4.639c26.042 0 51.111-9.669 70.361-26.85 19.25 17.181 44.319 26.85 70.36 26.85H331v-30h-4.64zM181 270h30v30h-30z" />
      <path d="M512 105V90h-15c-61.777 0-123.359-23.207-173.399-65.347C304.722 8.755 280.715 0 256 0c-24.714 0-48.722 8.755-67.601 24.653C138.359 66.793 76.778 90 15 90H0v15c0 58.79 38.058 108.915 91 127.395V240c-33.084 0-60 26.916-60 60s26.916 60 60 60h.675C99.129 443.848 169.406 512 256 512c86.567 0 156.869-68.121 164.325-152H421c33.084 0 60-26.916 60-60s-26.916-60-60-60v-7.605c52.941-18.48 91-68.605 91-127.395zM91 330c-16.542 0-30-13.458-30-30s13.458-30 30-30v60zm165 152c-74.439 0-135-61.458-135-137V239.175c4.927.539 9.93.825 15 .825h85.458l50.919 33.946A44.975 44.975 0 00271 285c0 24.813 20.187 45 45 45a44.766 44.766 0 0026.964-8.996l47.808 31.872C386.736 424.767 327.834 482 256 482zm45-197c0-8.271 6.729-15 15-15s15 6.729 15 15-6.729 15-15 15-15-6.729-15-15zm90-45v76.972l-31.378-20.918A44.927 44.927 0 00361 285c0-24.813-20.187-45-45-45a44.766 44.766 0 00-26.964 8.996L275.542 240H376c5.07 0 10.073-.286 15-.825V240zm60 60c0 16.542-13.458 30-30 30v-60c16.542 0 30 13.458 30 30zm-75-90H136c-53.468 0-97.818-39.422-104.988-90.462 63.372-3.653 125.571-28.871 176.711-71.937C221.201 36.25 238.346 30 256 30s34.799 6.25 48.276 17.601c51.141 43.066 113.34 68.284 176.711 71.937C473.818 170.578 429.469 210 376 210z" />
      <path d="M309.033 88.18L287.82 66.967 256 98.787l-31.82-31.82-21.213 21.213 31.82 31.82-31.82 31.82 21.213 21.213 31.82-31.82 31.82 31.82 21.213-21.213-31.82-31.82z" />
    </svg>
  );
}

export default SvgPirate;
