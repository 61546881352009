import React, {RefObject, useRef} from 'react';
import {useOnClickOutside} from "../hooks/use-on-click-outside";

type SoundButtonProps = {
    soundSrc: string;
    className: string;
    icon: JSX.Element;
    title: string;
}

const toggleAudioPlay = (audio: RefObject<HTMLAudioElement>) => {
    if (!audio.current) {
        return;
    }

    if (audio.current.paused) {
        audio.current.play();
    } else {
        audio.current.pause();
        audio.current.currentTime = 0;
    }
};

export const SoundButton: React.FC<SoundButtonProps> = ({ soundSrc, className, icon, title  }) => {
    const audioRef = useRef<HTMLAudioElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);

    useOnClickOutside(buttonRef, () => {
        if (!buttonRef.current || !audioRef.current) {
            return;
        }

        if (!audioRef.current.paused || audioRef.current.currentTime) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
        }
    });

    return (
        <div className={className}>
            {icon}
            <button
                ref={buttonRef}
                className="sounds__button"
                onClick={() => {
                    toggleAudioPlay(audioRef);
                }}
            >
                {title}
            </button>
            <audio
                ref={audioRef}
                src={soundSrc}>
                Your browser does not support the
                <code>audio</code> element.
            </audio>

        </div>
    )
};
