import * as React from "react";

function SvgBoing(props) {
  return (
    <svg
      height={512}
      viewBox="0 0 511.728 511.728"
      width={512}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M342.486 35.896V0h-30v145.234c0 8.271-6.729 15-15 15h-129.92a44.725 44.725 0 00-18.224 3.87c-7.088-18.606-25.104-31.864-46.168-31.864-20.578 0-38.546 12.487-45.903 31.126-10.222 6.724-16.506 18.125-16.506 30.767 0 20.342 16.549 36.891 36.891 36.891h44.91v50.703c0 24.813 20.187 45 45 45h94.481l-90.729 34.674v26.326h93.346l-93.346 35.674v26.326h93.346l-93.346 35.674v26.326h186.996v-30h-93.346l93.346-35.674v-26.326h-93.346l93.346-35.674v-26.326h-93.346l81.115-31h30.883c24.813 0 45-20.187 45-45v-125.99h3.997c24.813 0 45-20.187 45-45V55.896zm-129.92 154.338h69.199v46.493h-69.199zm-141.801 3.9c0-2.86 1.724-5.38 4.391-6.421l7.345-2.866 1.804-7.676c2.066-8.79 9.825-14.929 18.869-14.929 10.693 0 19.392 8.699 19.392 19.392v19.392h-44.91a6.9 6.9 0 01-6.891-6.892zm370.198-83.397c0 8.271-6.729 15-15 15h-33.997v155.99c0 8.271-6.729 15-15 15h-209.4c-8.271 0-15-6.729-15-15v-76.493c0-8.271 6.729-15 15-15h15v76.493h129.199v-78.829c17.833-5.984 30.721-22.842 30.721-42.664V66.257l98.477 15.33z" />
    </svg>
  );
}

export default SvgBoing;
