import * as React from "react";

function SvgDislike(props) {
  return (
    <svg
      height={512}
      viewBox="0 0 24 24"
      width={512}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M22.25 0h-2.5C18.785 0 18 .785 18 1.75v.366C16.488 1.346 13.054 0 7.469 0h-1.6a4.738 4.738 0 00-4.68 3.942l-1.12 6.5A4.75 4.75 0 004.749 16h4.536a9.33 9.33 0 00-.566 3.25c0 3.757 2.126 4.75 3.25 4.75 2.076 0 2.25-1.936 2.25-3.75 0-2.214 2.483-4.054 3.794-4.872A1.747 1.747 0 0019.75 17h2.5c.965 0 1.75-.785 1.75-1.75V1.75C24 .785 23.215 0 22.25 0zm-9.531 20.25c0 2.25-.381 2.25-.75 2.25-.704 0-1.75-.866-1.75-3.25 0-2.216.868-3.574.874-3.584a.75.75 0 00-.624-1.166h-5.72a3.248 3.248 0 01-3.201-3.802l1.12-6.5A3.24 3.24 0 015.869 1.5h1.6C14.272 1.5 17.68 3.628 18 3.839v9.81c-.833.442-5.281 2.972-5.281 6.601zm9.781-5a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25V1.75c0-.136.114-.25.25-.25h2.5a.25.25 0 01.25.25z" />
    </svg>
  );
}

export default SvgDislike;
