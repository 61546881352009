import * as React from "react";

function SvgToilet(props) {
  return (
    <svg
      height={512}
      viewBox="0 0 512.019 512.019"
      width={512}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M478.954 54.144C456.26 19.23 425.379.001 392 .001H120c-33.379 0-64.26 19.229-86.954 54.143C11.736 86.929 0 130.205 0 176.001v320c0 11.709 12.207 19.469 22.812 14.478l27.193-12.795 27.193 12.795a16 16 0 0013.624 0l27.188-12.794 27.189 12.794a15.996 15.996 0 0013.625 0l27.188-12.794 27.187 12.794a16.001 16.001 0 0013.626 0l27.182-12.793 27.181 12.793C291.784 515.466 304 507.72 304 496.001v-144h88c33.379 0 64.26-19.229 86.954-54.143 44.077-67.812 44.097-175.871 0-243.714zM272 470.787l-11.181-5.262a16 16 0 00-13.627 0l-27.182 12.793-27.187-12.793a16 16 0 00-13.625 0l-27.188 12.794-27.189-12.794a16 16 0 00-13.625 0L84.01 478.318l-27.193-12.794a15.998 15.998 0 00-13.623 0L32 470.79V176.001c0-39.679 9.899-76.762 27.876-104.417C76.468 46.059 97.82 32.001 120 32.001h202.51c-27.978 29.058-50.51 79.608-50.51 144zm32-174.577c5.377 8.485 11.431 16.439 18.51 23.791H304zm148.124-15.791c-34.343 52.834-85.984 52.713-120.248 0-37.147-57.152-37.259-151.511 0-208.835 34.343-52.834 85.984-52.713 120.248 0 37.147 57.152 37.259 151.511 0 208.835z" />
      <path d="M403.328 86.979c-6.249-6.265-16.406-6.267-22.656 0C360.689 107.009 352 145.222 352 176.001c0 30.296 8.525 68.827 28.672 89.022 6.249 6.265 16.406 6.267 22.656 0C423.311 244.994 432 206.78 432 176.001c0-30.296-8.525-68.827-28.672-89.022zM392 222.834c-10.542-27.387-10.79-65.634 0-93.665 10.542 27.387 10.79 65.634 0 93.665z" />
      <circle cx={56} cy={224.001} r={16} />
      <circle cx={104} cy={224.001} r={16} />
      <circle cx={152} cy={224.001} r={16} />
      <circle cx={200} cy={224.001} r={16} />
      <circle cx={248} cy={224.001} r={16} />
    </svg>
  );
}

export default SvgToilet;
