import * as React from "react";

function SvgChristmas(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <circle cx={286} cy={346} r={15} />
      <circle cx={226} cy={256} r={15} />
      <path d="M447.389 426.238L365.756 331H406a15 15 0 0011.523-24.603L338.026 211H346c12.131 0 19.523-13.964 12-24L280.525 83.7C292.839 75.662 301 61.77 301 46c0-24.867-20.096-46-45-46-24.873 0-45 21.096-45 46 0 15.77 8.161 29.662 20.475 37.7L154 187c-7.411 9.882-.323 23.9 12 23.9h7.974c-15.259 18.311-77.76 93.415-79.524 95.531C86.266 316.323 93.499 331 106 331h40.245l-81.633 95.238A15 15 0 0076 451h135v46c0 8.284 6.716 15 15 15h60c8.284 0 15-6.716 15-15v-46h135a15 15 0 0011.389-24.762zM256 30c7.991 0 15 7.477 15 16 0 8.271-6.729 15-15 15s-15-6.729-15-15c0-8.523 7.009-16 15-16zm0 71l60 80h-4.377c-28.591 0-54.295-15.886-67.082-41.459l-6.979-13.958L256 101zM138.025 301l79.498-95.397A15.001 15.001 0 00206 181h-10l21.438-28.583.271.541a104.94 104.94 0 0080.545 57.175l1.542 1.85C292.068 263.142 248.467 301 196 301H138.025zM271 482h-30v-31h30v31zm-162.387-61l63.478-74.057c27.972 30.96 62.035 56.317 99.766 74.057H108.613zm89.562-90.026c57.423-.916 106.983-37.845 125.331-90.538L373.975 301h-40.832a15 15 0 00-11.389 24.762l81.616 95.218c-77.781-.73-152.31-33.797-205.195-90.006z" />
    </svg>
  );
}

export default SvgChristmas;
