import * as React from "react";

function SvgShout(props) {
  return (
    <svg
      height="512pt"
      viewBox="0 0 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M484.887 352.121L512 307.117l-51.879-22.304-7.68-49.422-55.507 8.84-38.512-35.583-38.516 35.582-55.508-8.84-7.68 49.423-51.878 22.304 27.117 45.004-27.117 45 51.879 22.32 7.68 49.395 55.507-8.813 38.516 35.586 36.601-33.82 110.73 49.883-39.718-94.77L512 397.125zm-170.563 10.293h-29.398v-29.402h29.398zm58.797 0h-29.398v-29.402h29.398zm58.793 0H402.52v-29.402h29.394zm0 0" />
      <path d="M194.926 344.121l-35.54-58.976 68.235-29.34 9.758-62.785 70.855 11.293 48.184-44.504 12.277 11.34 26.657-44.227-66.504-28.61-9.907-63.703-71.5 11.383L197.676 0 147.91 45.992 76.395 34.61l-9.891 63.704L0 126.922l34.781 57.719L0 242.359l60.102 25.84L.07 398.105l149.977-72.832 36.125 33.38zm0 0" />
    </svg>
  );
}

export default SvgShout;
