import * as React from "react";

function SvgMoney(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <path d="M256 317c-8.271 0-15-6.729-15-15a14.988 14.988 0 019.159-13.825c6.717-2.844 14.755-.417 22.059 6.659 5.95 5.764 15.446 5.614 21.21-.335 5.765-5.95 5.614-15.446-.335-21.21-6.772-6.561-14.3-11.198-22.093-13.815V242c0-8.284-6.716-15-15-15s-15 6.716-15 15v17.571a46.57 46.57 0 00-2.54.979A44.936 44.936 0 00211 302c0 24.813 20.187 45 45 45 8.271 0 15 6.729 15 15 0 6.286-3.965 11.954-9.866 14.103-7.705 2.805-16.674-1-24.606-10.445-5.328-6.344-14.789-7.167-21.133-1.839s-7.167 14.79-1.839 21.133c8.109 9.655 17.548 16.276 27.445 19.608V422c0 8.284 6.716 15 15 15s15-6.716 15-15v-17.573c.134-.047.268-.088.401-.136C289.105 397.843 301 380.847 301 362c0-24.813-20.187-45-45-45z" />
      <path d="M394.919 225.259c-17.828-21.933-38.208-39.407-60.58-51.973a45.27 45.27 0 008.422-15.083c7.326-22.309-3.375-48.809-30.688-57.442l38.289-66.765c4.831-8.426.491-19.158-8.824-21.866C313.854 4.081 285.076 0 256 0s-57.854 4.081-85.538 12.13c-9.326 2.711-13.649 13.45-8.824 21.866l38.289 66.766c-27.205 8.589-38.041 35.05-30.684 57.453a45.075 45.075 0 008.426 15.067c-22.375 12.566-42.757 30.042-60.587 51.977C81.441 269.106 61 324.413 61 377c0 109.091 87.047 135 195 135 108.029 0 195-25.953 195-135 0-52.587-20.441-107.894-56.081-151.741zM197.496 36.218A277.21 277.21 0 01256 30a277.21 277.21 0 0158.504 6.218L281.471 93.82a183.992 183.992 0 00-50.941.001l-33.034-57.603zm9.8 93.678c25.815-8.468 52.823-10.073 79.08-4.824l.094.018a158.346 158.346 0 0118.213 4.799c7.861 2.591 12.157 11.094 9.58 18.941a14.955 14.955 0 01-12.635 10.231C286.672 154.378 271.417 152 256 152c-15.386 0-30.611 2.368-45.538 7.032-5.907-.608-10.864-4.55-12.721-10.188-2.581-7.861 1.715-16.365 9.555-18.948zM256 482.2c-117.196 0-165-30.62-165-105.2 0-45.142 18.453-94.793 49.36-132.818C172.955 204.083 214.023 182 256 182c46.206 0 86.621 26.482 115.64 62.182C402.547 282.207 421 331.858 421 377c0 74.58-47.804 105.2-165 105.2z" />
    </svg>
  );
}

export default SvgMoney;
