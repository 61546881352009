// @ts-nocheck
import React from "react";
import {
    Applause,
    Birthday,
    Boing,
    Boo,
    Broken,
    Christmas,
    Cuckoo,
    Devil,
    Dislike,
    Door,
    Drum,
    Heart,
    Horn,
    // Horror,
    Laughing,
    Money,
    Pirate,
    // Nerd,
    // Poop,
    // Proud,
    Rooster,
    Santa,
    Shocked,
    Shout,
    Stop,
    // Support,
    Toilet,
    // Whip,
    Witch,
    Yodle
} from "../icons";
import applause from '../assets/sounds/applause.mp3'
import aww from '../assets/sounds/aww.mp3'
import rooster from '../assets/sounds/rooster.mp3'
import drumroll from '../assets/sounds/drumroll.mp3'
import happyBirthdayCrowd from '../assets/sounds/happy-birthday-crowd.mp3'
import sitcomLaughterWithApplause from '../assets/sounds/sitcom-laughter-with-applause.mp3'
import toiletFlushing from '../assets/sounds/toilet-flushing.mp3'
import witchLaugh from '../assets/sounds/witch-laugh.mp3'
import boing from '../assets/sounds/boing.mp3'
import cash from '../assets/sounds/cash.mp3'
import pirate from '../assets/sounds/pirate.mp3'
import cockooClock from '../assets/sounds/cockoo-clock.mp3'
import christmasSong from '../assets/sounds/christmas-song.mp3'
import boo from '../assets/sounds/boo.mp3'
import crash from '../assets/sounds/crash.mp3'
import dramatic from '../assets/sounds/dramatic.mp3'
import evilLaugh from '../assets/sounds/evil-laugh.mp3'
import femaleScream from '../assets/sounds/female-scream.mp3'
import door from '../assets/sounds/door.mp3'
import merryChristmas from '../assets/sounds/merry-christmas.mp3'
import sadTrombone from '../assets/sounds/sad-trombone.mp3'
import stopIt from '../assets/sounds/stop-it.mp3'
import tada from '../assets/sounds/tada.mp3'
import yodle from '../assets/sounds/yodle.mp3'
// import parentsProud from '../assets/sounds/soundsnap/parents-proud.mp3'
// import partyHorn from '../assets/sounds/soundsnap/party-horn.mp3'
// import poopyDoopy from '../assets/sounds/soundsnap/poopy-doopy.mp3'
// import holyShit from '../assets/sounds/soundsnap/holy-shit.mp3'
// import horrorSwell from '../assets/sounds/soundsnap/horror-swell.mp3'
// import santaNaughty from '../assets/sounds/soundsnap/santa-naughty.mp3'
// import scottishHilarious from '../assets/sounds/soundsnap/scottish-hilarious.mp3'
// import techSupport from '../assets/sounds/soundsnap/tech-support.mp3'
// import whipSlash from '../assets/sounds/soundsnap/whip-slash.mp3'
// import eightBitLoop from '../assets/sounds/soundsnap/8bit-loop.mp3'
// import comeOnBoys from '../assets/sounds/soundsnap/come-on-boys.mp3'
// import cow from '../assets/sounds/soundsnap/cow.mp3'

export const sounds = [
    {
        title: 'applause',
        src: applause,
        icon: (<Applause width="40px" height="40px" className="sounds__icon" />),
    },{
        title: 'sitcomLaughterWithApplause',
        src: sitcomLaughterWithApplause,
        icon: (<Laughing width="40px" height="40px" className="sounds__icon" />),
    },{
        title: 'aww',
        src: aww,
        icon: (<Heart width="40px" height="40px" className="sounds__icon" />),
    },{
        title: 'drumroll',
        src: drumroll,
        icon: (<Drum width="40px" height="40px" className="sounds__icon" />),
    },{
        title: 'happyBirthdayCrowd',
        src: happyBirthdayCrowd,
        icon: (<Birthday width="40px" height="40px" className="sounds__icon" />),
    },{
        title: 'rooster',
        src: rooster,
        icon: (<Rooster width="40px" height="40px" className="sounds__icon" />),
    },{
        title: 'toiletFlushing',
        src: toiletFlushing,
        icon: (<Toilet width="40px" height="40px" className="sounds__icon" />),
    },{
        title: 'witch laugh',
        src: witchLaugh,
        icon: (<Witch width="40px" height="40px" className="sounds__icon" />),
    },{
        title: 'evil laugh',
        src: evilLaugh,
        icon: (<Devil width="40px" height="40px" className="sounds__icon" />),
    },{
        title: 'female scream',
        src: femaleScream,
        icon: (<Shout width="40px" height="40px" className="sounds__icon" />),
    },{
        title: 'cockoo clock',
        src: cockooClock,
        icon: (<Cuckoo width="40px" height="40px" className="sounds__icon" />),
    },{
        title: 'pirate',
        src: pirate,
        icon: (<Pirate width="40px" height="40px" className="sounds__icon" />),
    },{
        title: 'boing',
        src: boing,
        icon: (<Boing width="40px" height="40px" className="sounds__icon" />),
    },{
        title: 'cash',
        src: cash,
        icon: (<Money width="40px" height="40px" className="sounds__icon" />),
    },{
        title: 'crash',
        src: crash,
        icon: (<Broken width="40px" height="40px" className="sounds__icon" />),
    }, {
        title: 'door',
        src: door,
        icon: (<Door width="40px" height="40px" className="sounds__icon" />),
    }, {
        title: 'merry christmas',
        src: merryChristmas,
        icon: (<Santa width="40px" height="40px" className="sounds__icon" />),
    },{
        title: 'christmas song',
        src: christmasSong,
        icon: (<Christmas width="40px" height="40px" className="sounds__icon" />),
    },

    // {
    // title: 'santaNaughty',
    //     src: santaNaughty,
    //     icon: (<Santa width="40px" height="40px" className="sounds__icon" />),
    // },

    // {
    // title: 'partyHorn',
    //     src: partyHorn,
    //     icon: (<Horn width="40px" height="40px" className="sounds__icon" />),
    // },

    // {
    // title: 'poopyDoopy',
    //     src: poopyDoopy,
    //     icon: (<Poop width="40px" height="40px" className="sounds__icon" />),
    // },

    {
        title: 'tada',
        src: tada,
        icon: (<Horn width="40px" height="40px" className="sounds__icon" />),
    }, {
        title: 'sadTrombone',
        src: sadTrombone,
        icon: (<Dislike width="40px" height="40px" className="sounds__icon" />),
    },

    // {
    //     title: 'comeOnBoys',
    //     src: comeOnBoys,
    //     icon: (<Nerd width="40px" height="40px" className="sounds__icon" />),
    // },{
    //     title: 'parentsProud',
    //     src: parentsProud,
    //     icon: (<Proud width="40px" height="40px" className="sounds__icon" />),
    // },{
    //     title: 'techSupport',
    //     src: techSupport,
    //     icon: (<Support width="40px" height="40px" className="sounds__icon" />),
    // },

    // {
    //     title: 'whipSlash',
    //     src: whipSlash,
    //     icon: (<Whip width="40px" height="40px" className="sounds__icon" />),
    // },

    {
        title: 'yodle',
        src: yodle,
        icon: (<Yodle width="40px" height="40px" className="sounds__icon" />),
    },

    // {
    //     title: 'horrorSwell',
    //     src: horrorSwell,
    //     icon: (<Horror width="40px" height="40px" className="sounds__icon" />),
    // },
    {
        title: 'boo',
        src: boo,
        icon: (<Boo width="40px" height="40px" className="sounds__icon" />),
    }, {
        title: 'dramatic',
        src: dramatic,
        icon: (<Shocked width="40px" height="40px" className="sounds__icon" />),
    }, {
        title: 'stop it',
        src: stopIt,
        icon: (<Stop width="40px" height="40px" className="sounds__icon" />),
    }
];




