import * as React from "react";

function SvgLaughing(props) {
  return (
    <svg
      height={512}
      viewBox="0 0 511.926 511.926"
      width={512}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M362.219 289.878H149.607c-8.284 0-15 6.716-15 15v1.849c0 66.889 54.417 121.306 121.306 121.306 66.888 0 121.306-54.417 121.306-121.306v-1.849c0-8.285-6.716-15-15-15zM255.913 398.032a90.786 90.786 0 01-35.029-7.008c6.911-6.251 19.12-11.26 34.557-11.26 15.742 0 28.029 5.154 34.858 11.522a90.764 90.764 0 01-34.386 6.746zm60.266-22.779c-12.524-15.507-35.348-25.489-60.738-25.489-25.075 0-47.727 9.684-60.352 24.98-15.599-13.964-26.397-33.188-29.538-54.867h180.723c-3.178 21.94-14.191 41.373-30.095 55.376z" />
      <path d="M494.381 242.536c-.354-.354-.72-.698-1.088-1.041a14.92 14.92 0 00.053-2.602c-4.273-59.799-30.906-115.446-74.993-156.691-44.307-41.452-101.997-64.28-162.44-64.28-61.748 0-120.344 23.689-164.994 66.705-43.423 41.831-69.096 97.501-72.619 157.188-.252.24-.509.476-.755.722-23.394 23.394-23.393 61.459 0 84.853a59.783 59.783 0 0015.072 10.968c34.556 93.249 123.826 155.648 223.295 155.648 99.587 0 188.71-62.325 223.283-155.592a59.772 59.772 0 0015.185-11.023c23.394-23.396 23.394-61.46.001-84.855zM38.758 263.75c4.943-4.943 16.572-9.553 30.347-12.03 7.151-1.286 13.397-1.735 18.223-1.735 2.984 0 5.424.172 7.198.424.661 4.643.77 13.848-1.311 25.421-2.477 13.775-7.087 25.404-12.03 30.347-11.697 11.697-30.73 11.696-42.427 0-11.696-11.698-11.696-30.73 0-42.427zm217.155 200.255c-80.929 0-154.139-47.276-188.238-119.577 12.705-1.631 24.986-7.301 34.724-17.038 14.366-14.367 19.523-40.247 21.074-50.706 1.994-13.452 3.745-37.612-6.932-48.289-10.678-10.676-34.836-8.925-48.289-6.932a142.93 142.93 0 00-18.148 3.929c14.767-101.14 101.241-177.47 205.809-177.47 103.323 0 190.829 76.749 205.783 177.434a143.004 143.004 0 00-18.021-3.894c-13.451-1.993-37.61-3.745-48.289 6.932-10.677 10.677-8.927 34.837-6.933 48.289 1.551 10.46 6.708 36.34 21.075 50.707 9.713 9.713 21.957 15.38 34.628 17.027-34.084 72.337-107.198 119.588-188.243 119.588zm217.255-157.829c-11.697 11.695-30.729 11.697-42.427 0-4.943-4.944-9.554-16.572-12.031-30.347-2.081-11.574-1.972-20.779-1.311-25.421 4.643-.66 13.85-.769 25.421 1.312 13.775 2.477 25.404 7.087 30.348 12.03 11.696 11.697 11.696 30.729 0 42.426z" />
      <path d="M390.651 185.228a74.895 74.895 0 00-57.06-26.326 74.896 74.896 0 00-57.047 26.311c-5.378 6.301-4.63 15.769 1.672 21.147 6.301 5.377 15.769 4.63 21.146-1.672a44.939 44.939 0 0134.229-15.787 44.934 44.934 0 0134.235 15.795 14.965 14.965 0 0011.419 5.265c3.442 0 6.903-1.179 9.728-3.588 6.303-5.374 7.054-14.842 1.678-21.145zM144.078 204.689a44.939 44.939 0 0134.229-15.787 44.938 44.938 0 0134.236 15.795 14.959 14.959 0 0011.419 5.265c3.442 0 6.903-1.179 9.728-3.588 6.303-5.376 7.053-14.844 1.677-21.146a74.896 74.896 0 00-57.06-26.326 74.9 74.9 0 00-57.047 26.311c-5.378 6.301-4.63 15.769 1.671 21.147 6.3 5.378 15.768 4.631 21.147-1.671z" />
    </svg>
  );
}

export default SvgLaughing;
