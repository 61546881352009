import * as React from "react";

function SvgDoor(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <path d="M496.5 482h-45V45c0-24.813-20.187-45-45-45h-301c-24.813 0-45 20.187-45 45v437h-45c-8.284 0-15 6.716-15 15s6.716 15 15 15h481c8.284 0 15-6.716 15-15s-6.716-15-15-15zm-135 0h-211V90h211v392zm60 0h-30V75c0-8.284-6.716-15-15-15h-241c-8.284 0-15 6.716-15 15v407h-30V45c0-8.271 6.729-15 15-15h301c8.271 0 15 6.729 15 15v437z" />
      <path d="M331.21 284.07c-.1-.48-.22-.96-.36-1.43-.15-.46-.31-.93-.5-1.38s-.4-.89-.63-1.32a14.6 14.6 0 00-.75-1.27c-.27-.4-.56-.8-.87-1.18s-.65-.75-.99-1.1c-.35-.34-.72-.68-1.1-.99s-.77-.6-1.18-.87c-.41-.27-.83-.52-1.26-.75-.43-.23-.88-.44-1.33-.63-.45-.19-.92-.35-1.38-.5-.47-.14-.95-.26-1.43-.36-.48-.09-.97-.17-1.45-.22-.98-.09-1.98-.09-2.96 0-.48.05-.97.13-1.45.22-.48.1-.96.22-1.43.36-.46.15-.93.31-1.38.5s-.89.4-1.32.63a14.6 14.6 0 00-2.45 1.62c-.38.31-.75.65-1.1.99-.34.35-.68.72-.99 1.1s-.6.78-.87 1.18c-.27.41-.52.83-.75 1.27-.23.43-.44.87-.63 1.32s-.35.92-.5 1.38c-.14.47-.26.95-.36 1.43-.09.48-.17.97-.21 1.46-.05.48-.08.98-.08 1.47s.03.99.08 1.48c.04.48.12.97.21 1.45.1.48.22.96.36 1.43.15.46.31.93.5 1.38s.4.89.63 1.32c.23.44.48.86.75 1.27.27.4.56.8.87 1.18s.65.75.99 1.1c.35.34.72.68 1.1.99a14.339 14.339 0 002.45 1.62c.43.23.87.44 1.32.63s.92.35 1.38.5c.47.14.95.26 1.43.36.48.09.97.17 1.45.22.49.05.99.07 1.48.07s.99-.02 1.48-.07c.48-.05.97-.13 1.45-.22.48-.1.96-.22 1.43-.36.46-.15.93-.31 1.38-.5s.9-.4 1.33-.63c.43-.23.85-.48 1.26-.75.41-.27.8-.56 1.18-.87s.75-.65 1.1-.99c.34-.35.68-.72.99-1.1s.6-.78.87-1.18c.27-.41.52-.83.75-1.27.23-.43.44-.87.63-1.32s.35-.92.5-1.38c.14-.47.26-.95.36-1.43.09-.48.17-.97.22-1.45.05-.49.07-.99.07-1.48s-.02-.99-.07-1.47c-.05-.49-.13-.98-.22-1.46zM195.5 210h121c8.284 0 15-6.716 15-15v-60c0-8.284-6.716-15-15-15h-121c-8.284 0-15 6.716-15 15v60c0 8.284 6.716 15 15 15zm15-60h91v30h-91v-30z" />
    </svg>
  );
}

export default SvgDoor;
