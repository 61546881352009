import * as React from "react";

function SvgHeart(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <path d="M471.67 82.457C446.515 56.984 411.829 42.955 374 42.955c-52.941 0-93.74 25.485-118 73.711-24.26-48.226-65.059-73.711-118-73.711-37.829 0-72.515 14.029-97.67 39.503C13.946 109.177 0 145.752 0 188.229c0 50.951 32.376 107.006 96.228 166.607 57.894 54.039 121.343 91.964 133.475 99.018a7806.25 7806.25 0 0016.306 9.427l9.994 5.764 9.993-5.767s11.242-6.487 16.294-9.425c12.132-7.055 75.584-44.983 133.477-99.018C479.622 295.235 512 239.18 512 188.229c0-42.477-13.946-79.052-40.33-105.772zm-83.196 243.137c-54.759 51.11-114.809 87.004-126.291 93.68-1.617.94-3.872 2.246-6.186 3.585a2809.167 2809.167 0 01-6.188-3.585c-11.481-6.676-71.527-42.567-126.287-93.68C68.882 274.592 40 227.092 40 188.229c0-61.984 40.299-105.274 98-105.274 37.536 0 64.303 16.625 81.828 50.826 14.064 27.441 16.196 55.891 16.216 56.175l.768-.052h39.146c.018-.274 2.152-28.119 15.719-55.149 17.251-34.372 44.949-51.8 82.323-51.8 57.701 0 98 43.29 98 105.274 0 38.863-28.883 86.363-83.526 137.365z" />
    </svg>
  );
}

export default SvgHeart;
