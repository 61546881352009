import * as React from "react";

function SvgBirthday(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <path d="M480 143.686H378.752c7.264-4.96 13.504-9.888 17.856-14.304 25.792-25.952 25.792-68.192 0-94.144-25.056-25.216-68.768-25.248-93.856 0-13.856 13.92-50.688 70.592-45.6 108.448h-2.304c5.056-37.856-31.744-94.528-45.6-108.448-25.088-25.248-68.8-25.216-93.856 0C89.6 61.19 89.6 103.43 115.36 129.382c4.384 4.416 10.624 9.344 17.888 14.304H32c-17.632 0-32 14.368-32 32v80c0 8.832 7.168 16 16 16h16v192c0 17.632 14.368 32 32 32h384c17.632 0 32-14.368 32-32v-192h16c8.832 0 16-7.168 16-16v-80c0-17.632-14.368-32-32-32zM138.08 57.798c6.496-6.528 15.104-10.112 24.256-10.112 9.12 0 17.728 3.584 24.224 10.112 21.568 21.696 43.008 77.12 35.552 84.832 0 0-1.344 1.056-5.92 1.056-22.112 0-64.32-22.976-78.112-36.864-13.408-13.504-13.408-35.52 0-49.024zM240 463.686H64v-192h176v192zm0-224H32v-64h208v64zm85.44-181.888c12.992-13.024 35.52-12.992 48.48 0 13.408 13.504 13.408 35.52 0 49.024-13.792 13.888-56 36.864-78.112 36.864-4.576 0-5.92-1.024-5.952-1.056-7.424-7.712 14.016-63.136 35.584-84.832zM448 463.686H272v-192h176v192zm32-224H272v-64h208v64z" />
    </svg>
  );
}

export default SvgBirthday;
